input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.meal-square-img{
  transition-duration: 0.1s;

}
.meal-square-img:hover {
  opacity: 0.8 !important;
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}
.arrow-btn {
  top: 45%;
  z-index: 1;
}
.btn-light:hover{
  background-color: white !important;
}
.next {
  float: right;
}
.recipe-card:hover {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24) !important;
}
.recipe-card{
  transition-duration: 0.3s;
}
.recipe-card-bg:hover{
  background-color: rgb(241, 245, 246) !important;
}
.recipe-card-bg:hover .svg-bg{
  fill: rgb(241, 245, 246) !important;
}
.search-field:hover{
  box-shadow: 0 2px 4px rgba(0,0,0,0.3) !important;
  background-color: white !important;
}
.search-field{
  transition-duration: 0.3s;
}
.search-field-expand:hover {
  width: 250px !important;
  transition: width 350ms ease-out !important;
}
.search-field-expand:focus {
  width: 250px !important;
  transition: width 350ms ease-out !important;
}
.icon-grow{
  -webkit-transition-duration: 0.3s !important;
  transition-duration: 0.3s !important;
}
.icon-grow:hover{
  transform: scale(1.2);
}
.svg-bg{
  transition-duration: 0.3s;
}
.recipe-card-bg{
  transition-duration: 0.3s;
}
@media only screen and (min-width: 600px) {
  .recipe-card:hover {
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24) !important;
      transform: translateY(-4px);
  }
  .recipe-card{
    transition-duration: 0.3s;
  }
}
.icon {
  stroke-width: 2px !important;
  transition-duration: 0.3s;
}
.icon:hover{
  color: #E87B51 !important;
}

.icon-hover{
  transition: 0.2s;
}
.icon-hover:hover{
  background-color: rgba(50, 50, 50, 0.7) !important;
}
.icon-hover-light:hover{
  background-color: rgba(50, 50, 50, 0.3) !important;
}
.icon-hover-primary{
  transition: 0.2s;
}
.icon-hover-primary:hover{
  background-color: rgb(162, 86, 56) !important;
}
.custom_list .icon{
  visibility: hidden;
  transition: 0s !important;
}
.custom_list:hover .icon{
 visibility: visible;
}

.noscroll::-webkit-scrollbar {
   display: none;
}
.noscroll::-webkit-scrollbar {
  display: none;
}
.hoverColor:hover {
  transition: 0.4s;
  background: #ccc !important;
}
.meal:hover .menuName{
   color:#E87B51;
   transition:0s;
}
.meal:hover{
   cursor:pointer;
   transition:0s;
}
.hoverRaise{
  transition: 0.2s !important;
  border: 0px solid rgba(0, 0, 0,0.1);
}
.twolinesText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 3.2em !important;
  line-height: 1.6em !important;
}
.twolinesContainer{
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 3.2em !important;
  line-height: 1.6em !important;
}
.twolinesShortText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 2.4em !important;
  line-height: 1.2em !important;
}
.twolinesShortContainer{
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 2.4em !important;
  line-height: 1.2em !important;
}
.onelinesText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 1.6em !important;
  line-height: 1.6em !important;
}
.onelinesContainer{
  display: block;/* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 1.6em !important;
  line-height: 1.6em !important;
}
.hoverText{
  transition:0.4s !important;
}
.hoverIcon{
  transition:0.4s !important;
}
.hoverRaise:hover{
  /*box-shadow:1px 3px 10px rgba(0,0,0,0.3) !important;*/
  border: 2px solid #3f9e83;
}
.hoverRaise:hover .hoverText{
  color:#E87B51 !important;
}
.hoverNoRaise:hover .hoverText{
  color:#E87B51 !important;
}
.hoverNoRaise:hover .hoverIcon{
  color:#E87B51 !important;
}
.hoverNoRaise:hover .hoverTextAlt{
  color:#E0E0E0 !important;
}
.hoverRaise:hover .hoverTextAlt{
  color:#E0E0E0 !important;
}
.hoverNoRaise:hover .hoverIconConfirm{
  color:#2bb58b !important;
}
.hoverNoRaise:hover .hoverTextConfirm{
  color:#2bb58b !important;
}
.hoverText:hover{
  color:#E87B51 !important;
}
.hoverIcon:hover{
  color:#E87B51 !important;
}
.hoverTextConfirm:hover{
  color:#2bb58b !important;
}
.hoverIconConfirm:hover{
  color:#2bb58b !important;
}
.hoverTextConfirm:hover .hoverIconConfirm{
  color:#2bb58b !important;
}
.hoverText:hover .hoverIcon{
  color:#E87B51 !important;
}
.icon-rotate{
  transition:  all 300ms ease-in-out;
  transform: rotate(-45deg);
}
.icon-rotate:hover {
  transform: rotate(-45deg);
  transition:  all 300ms ease-in-out;
}
.icon-normal{
  transition:  all 300ms ease-in-out;
  transform: rotate(0deg);
}
.normal:hover {
  transform: rotate(0deg);
  transition:  all 300ms ease-in-out;
}
.transition{
  transition: all 3s ease-in-out;
}

