body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display:swap;
}
@font-face {
  font-display: swap;
  font-family: 'Recoleta';
  font-weight: 700;
  src: local('Recoleta'), url(./styles/fonts/Recoleta-Bold.ttf) format('truetype');
}
iframe{
  z-index: -10000 !important;
}